import React from 'react';
import { Link } from 'gatsby';
import { useForm } from 'react-hook-form';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { Container, Row, Col } from 'react-grid-system';
import Hero from '../components/rows/hero/index';

import Banner from '../images/hero-images/research-hero.jpg';

export default function ReferralForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    if (errors) {
      console.log(errors);
    }
    fetch('/.netlify/functions/referral-form', {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => console.log(res));
  };

  return (
    <Layout>
      <Seo title="Referral Form" description="Referral Form." />
      <Hero title="Referral Form" banner={Banner} />
      <Container className="my-52">
        <Row>
          <Col md={6} sm={12} className="mx-auto">
            <h2 className="text-center">
              <span className="text-red-300">Don't have </span> a family doctor
              or nurse practitioner?
            </h2>
            <p className="text-center">
              Are you concerned about your cardiovascular health? We can help!
              Fill out the following online form if you do not have a doctor.
            </p>
          </Col>
        </Row>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="firstName"
            >
              First Name
            </label>
            <input
              type="text"
              name="First Name"
              id="firstName"
              {...register('First Name', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="lastName"
            >
              Last Name
            </label>
            <input
              type="text"
              name="Last Name"
              id="lastName"
              {...register('Last Name', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="pronouns"
            >
              What are your pronouns?
            </label>
            <select
              {...register('pronouns', { required: true })}
              id="pronouns"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="She/Her">She/Her</option>
              <option value="He/Him">He/Him</option>
              <option value="They/It">They/It</option>
            </select>
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="birth"
            >
              Date of Birth
            </label>
            <input
              type="datetime-local"
              name="Birth"
              id="birth"
              {...register('Birth', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="healthCard"
            >
              Health Card #
            </label>
            <input
              type="text"
              name="HealthCard"
              id="healthCard"
              {...register('HealthCard', {})}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="vrs"
            >
              Vrs. Code
            </label>
            <input
              type="text"
              name="Vrs"
              {...register('Vrs', { required: true })}
              id="vrs"
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="mainPhone"
            >
              Main Phone #
            </label>
            <input
              type="tel"
              id="mainPhone"
              name="MainPhone"
              {...register('MainPhone', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="Email"
              {...register('Email', {})}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label htmlFor="mainSymptoms">
              1. What are the main cardiac symptoms you are experiencing?
            </label>
            <textarea
              {...register('mainSymptoms', { required: true })}
              id="mainSymptoms"
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label htmlFor="medications">
              2. What medications are you currently taking?
            </label>
            <textarea
              {...register('medications', { required: true })}
              id="medications"
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div>
            <p>
              Patient consents to electronic communication of personal health
              information using email address and/or phone number provided, as
              explained at{' '}
              <Link to="/privacy">www.pace-cardiology.com/privacy.</Link>
            </p>
            <span className="radio">
              {/* Yes */}
              <label htmlFor="electCommY">Yes</label>
              <input
                {...register('electComm', {})}
                type="radio"
                id="electCommY"
                name="electComm"
                value="Yes"
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
            </span>
            <span className="radio">
              {/* No */}
              <label htmlFor="electCommN">No</label>
              <input
                {...register('electComm', {})}
                type="radio"
                id="electCommN"
                name="electComm"
                value="No"
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
            </span>
          </div>
          <div className="button-wrapper">
            <button type="submit" className="form-button red-button">
              Submit Form
            </button>
            <button className="form-button blue-button">Download PDF</button>
          </div>
        </form>
      </Container>
    </Layout>
  );
}
